import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import { JNPLContactNumber } from "@jnpl/components/atoms/JNPLContactNumber";
import * as S from "./styles";

/**
 * Thank you page after completing the checkout.
 */
var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement("h3", null, "Thank you for your Order at Jeevaniya"), React.createElement("br", null), React.createElement("p", null, "Your", " ", React.createElement("span", null, React.createElement("strong", null, "Order Number")), " ", "is #", orderNumber), React.createElement("p", null, "Order confirmation is sent on your registered email id. You will receive a notification when your order will be dispatched. For any query or assistance, kindly ", React.createElement(JNPLContactNumber, null))), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping,
    color: "secondary",
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.3752098833",
    defaultMessage: "ORDER DETAILS"
  })))));
};

export { ThankYou };