import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  ", "\n\n  mutation OrderCancel($id: ID!) {\n    orderCancel(id: $id) {\n      errors: orderErrors {\n        ...OrderErrorFragment\n      }\n    }\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  fragment OrderErrorFragment on OrderError {\n    code\n    field\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  fragment OrderDetailsFragment on Order {\n    id\n    billingAddress {\n      ...AddressFragment\n    }\n    canFinalize\n    created\n    customerNote\n    events {\n      ...OrderEventFragment\n    }\n    fulfillments {\n      ...FulfillmentFragment\n    }\n    lines {\n      ...OrderLineFragment\n    }\n    number\n    paymentStatus\n    shippingAddress {\n      ...AddressFragment\n    }\n    shippingMethod {\n      id\n    }\n    shippingMethodName\n    shippingPrice {\n      gross {\n        amount\n        currency\n      }\n    }\n    status\n    subtotal {\n      gross {\n        amount\n        currency\n      }\n    }\n    total {\n      gross {\n        amount\n        currency\n      }\n      tax {\n        amount\n        currency\n      }\n    }\n    actions\n    totalAuthorized {\n      amount\n      currency\n    }\n    totalCaptured {\n      amount\n      currency\n    }\n    user {\n      id\n      email\n    }\n    userEmail\n    availableShippingMethods {\n      id\n      name\n      price {\n        amount\n        currency\n      }\n    }\n    discount {\n      amount\n      currency\n    }\n    invoices {\n      ...InvoiceFragment\n    }\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  fragment InvoiceFragment on Invoice {\n    id\n    number\n    createdAt\n    url\n    status\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  fragment FulfillmentFragment on Fulfillment {\n    id\n    lines {\n      id\n      quantity\n      orderLine {\n        ...OrderLineFragment\n      }\n    }\n    fulfillmentOrder\n    status\n    trackingNumber\n    warehouse {\n      id\n      name\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  fragment OrderLineFragment on OrderLine {\n    id\n    isShippingRequired\n    variant {\n      id\n      product {\n        id\n        isAvailableForPurchase\n        isPublished\n      }\n      quantityAvailable\n    }\n    productName\n    productSku\n    quantity\n    quantityFulfilled\n    unitPrice {\n      gross {\n        amount\n        currency\n      }\n      net {\n        amount\n        currency\n      }\n    }\n    thumbnail {\n      url\n    }\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  fragment OrderEventFragment on OrderEvent {\n    id\n    amount\n    date\n    email\n    emailType\n    invoiceNumber\n    message\n    quantity\n    type\n    user {\n      id\n      email\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment AddressFragment on Address {\n    city\n    cityArea\n    companyName\n    country {\n      code\n      country\n    }\n    countryArea\n    firstName\n    id\n    lastName\n    phone\n    postalCode\n    streetAddress1\n    streetAddress2\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
export var orderAddressFragment = gql(_templateObject());
export var orderEventFragment = gql(_templateObject2());
export var orderLineFragment = gql(_templateObject3());
export var orderFullfillmentFragment = gql(_templateObject4());
export var orderInvoiceFragment = gql(_templateObject5());
export var orderDetailsFragment = gql(_templateObject6());
export var orderErrorFragment = gql(_templateObject7());
export var orderCancelMutation = gql(_templateObject8(), orderErrorFragment);
export var TypeOrderCancelMutation = TypedMutation(orderCancelMutation);