import React from "react";
import { Modal } from "@components/organisms";
import { JNPLContactNumber } from "@jnpl/components/atoms/JNPLContactNumber";
export var OrderCancellationModal = function OrderCancellationModal(_ref) {
  var hideModal = _ref.hideModal,
      title = _ref.title,
      visible = _ref.visible,
      cancelOrder = _ref.cancelOrder;
  return React.createElement(Modal, {
    submitButtonTestingContext: "submitCancelModalButton",
    testingContext: "submitCancelModal",
    hide: function hide() {
      hideModal();
    },
    disabled: false,
    show: visible,
    submitBtnText: "Proceed to cancel",
    onSubmit: cancelOrder,
    title: title
  }, React.createElement("h2", null, React.createElement("p", null, "Namaste!"), React.createElement("br", null), React.createElement("p", null, "You are about to cancel this order. If there is anything else you want to add or ask, ", React.createElement(JNPLContactNumber, null)), React.createElement("br", null), React.createElement("p", null, "If you are sure about cancelling this order, please click the", " ", React.createElement("span", null, React.createElement("strong", null, "PROCEDD TO CANCEL")), " ", "button below."), React.createElement("br", null), React.createElement("p", null, "We wish to see you soon!"), React.createElement("p", null, "Eat Organic, be healthy!")));
};