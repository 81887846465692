import { Button } from "@components/atoms";
import React from "react";
import * as S from "./styles";
export var AddToCartButton = function AddToCartButton(_ref) {
  var disabled = _ref.disabled,
      variantId = _ref.variantId,
      items = _ref.cartItems,
      addItem = _ref.addItemToCart;
  var cartLineForVariant = items && items.find(function (item) {
    return item.variant.id === variantId;
  });
  var currentQuantitty = cartLineForVariant ? cartLineForVariant.quantity : 0;
  disabled = true;
  return React.createElement(React.Fragment, null, React.createElement(S.JnplAddToCartButton, {
    hidden: !currentQuantitty
  }, React.createElement("div", {
    className: "quantityChangeRow"
  }, React.createElement(Button, {
    size: "sm",
    className: "quantityChangeButton",
    testingContext: "decreaseProductQuantityInCartButton",
    onClick: function onClick(e) {
      e.preventDefault();
      e.stopPropagation();
      addItem(variantId, -1);
    },
    color: "primary",
    disabled: currentQuantitty < 1
  }, React.createElement("span", {
    className: "quantityChangeButtonFontSize"
  }, "-")), React.createElement("span", {
    className: "quantity"
  }, currentQuantitty, React.createElement("span", {
    className: "quantity-description"
  }, " in basket")), React.createElement(Button, {
    size: "sm",
    className: "quantityChangeButton",
    testingContext: "increaseProductQuantityInCartButton",
    onClick: function onClick(e) {
      e.preventDefault();
      e.stopPropagation();
      addItem(variantId, 1);
    },
    color: "primary",
    disabled: disabled
  }, React.createElement("span", {
    className: "quantityChangeButtonFontSize"
  }, "+")))), React.createElement(S.JnplAddToCartButton, {
    hidden: !!currentQuantitty
  }, React.createElement(Button, {
    className: "addToCartNew",
    fullWidth: true,
    testingContext: "addProductToCartButton",
    onClick: function onClick(e) {
      e.preventDefault();
      e.stopPropagation();
      addItem(variantId, 1);
    },
    color: "primary",
    disabled: disabled
  }, "Add to basket")));
};