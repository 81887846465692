import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation createPayment($input: PaymentInput!, $checkoutId: ID!) {\n    checkoutPaymentCreate(input: $input, checkoutId: $checkoutId) {\n      errors {\n        field\n        message\n      }\n      payment {\n        token\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { TypedMutation } from "@temp/core/mutations";
import gql from "graphql-tag";
var paymentMethodCreateMutation = gql(_templateObject());
export var TypedPaymentMethodCreateMutation = TypedMutation(paymentMethodCreateMutation);