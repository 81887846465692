/* tslint:disable */

/* eslint-disable */
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
export var CategorySortField;
/**
 * An enumeration.
 */

(function (CategorySortField) {
  CategorySortField["NAME"] = "NAME";
  CategorySortField["PRODUCT_COUNT"] = "PRODUCT_COUNT";
  CategorySortField["SUBCATEGORY_COUNT"] = "SUBCATEGORY_COUNT";
  CategorySortField["TREE_ID"] = "TREE_ID";
})(CategorySortField || (CategorySortField = {}));

export var CountryCode;
/**
 * An enumeration.
 */

(function (CountryCode) {
  CountryCode["AD"] = "AD";
  CountryCode["AE"] = "AE";
  CountryCode["AF"] = "AF";
  CountryCode["AG"] = "AG";
  CountryCode["AI"] = "AI";
  CountryCode["AL"] = "AL";
  CountryCode["AM"] = "AM";
  CountryCode["AO"] = "AO";
  CountryCode["AQ"] = "AQ";
  CountryCode["AR"] = "AR";
  CountryCode["AS"] = "AS";
  CountryCode["AT"] = "AT";
  CountryCode["AU"] = "AU";
  CountryCode["AW"] = "AW";
  CountryCode["AX"] = "AX";
  CountryCode["AZ"] = "AZ";
  CountryCode["BA"] = "BA";
  CountryCode["BB"] = "BB";
  CountryCode["BD"] = "BD";
  CountryCode["BE"] = "BE";
  CountryCode["BF"] = "BF";
  CountryCode["BG"] = "BG";
  CountryCode["BH"] = "BH";
  CountryCode["BI"] = "BI";
  CountryCode["BJ"] = "BJ";
  CountryCode["BL"] = "BL";
  CountryCode["BM"] = "BM";
  CountryCode["BN"] = "BN";
  CountryCode["BO"] = "BO";
  CountryCode["BQ"] = "BQ";
  CountryCode["BR"] = "BR";
  CountryCode["BS"] = "BS";
  CountryCode["BT"] = "BT";
  CountryCode["BV"] = "BV";
  CountryCode["BW"] = "BW";
  CountryCode["BY"] = "BY";
  CountryCode["BZ"] = "BZ";
  CountryCode["CA"] = "CA";
  CountryCode["CC"] = "CC";
  CountryCode["CD"] = "CD";
  CountryCode["CF"] = "CF";
  CountryCode["CG"] = "CG";
  CountryCode["CH"] = "CH";
  CountryCode["CI"] = "CI";
  CountryCode["CK"] = "CK";
  CountryCode["CL"] = "CL";
  CountryCode["CM"] = "CM";
  CountryCode["CN"] = "CN";
  CountryCode["CO"] = "CO";
  CountryCode["CR"] = "CR";
  CountryCode["CU"] = "CU";
  CountryCode["CV"] = "CV";
  CountryCode["CW"] = "CW";
  CountryCode["CX"] = "CX";
  CountryCode["CY"] = "CY";
  CountryCode["CZ"] = "CZ";
  CountryCode["DE"] = "DE";
  CountryCode["DJ"] = "DJ";
  CountryCode["DK"] = "DK";
  CountryCode["DM"] = "DM";
  CountryCode["DO"] = "DO";
  CountryCode["DZ"] = "DZ";
  CountryCode["EC"] = "EC";
  CountryCode["EE"] = "EE";
  CountryCode["EG"] = "EG";
  CountryCode["EH"] = "EH";
  CountryCode["ER"] = "ER";
  CountryCode["ES"] = "ES";
  CountryCode["ET"] = "ET";
  CountryCode["EU"] = "EU";
  CountryCode["FI"] = "FI";
  CountryCode["FJ"] = "FJ";
  CountryCode["FK"] = "FK";
  CountryCode["FM"] = "FM";
  CountryCode["FO"] = "FO";
  CountryCode["FR"] = "FR";
  CountryCode["GA"] = "GA";
  CountryCode["GB"] = "GB";
  CountryCode["GD"] = "GD";
  CountryCode["GE"] = "GE";
  CountryCode["GF"] = "GF";
  CountryCode["GG"] = "GG";
  CountryCode["GH"] = "GH";
  CountryCode["GI"] = "GI";
  CountryCode["GL"] = "GL";
  CountryCode["GM"] = "GM";
  CountryCode["GN"] = "GN";
  CountryCode["GP"] = "GP";
  CountryCode["GQ"] = "GQ";
  CountryCode["GR"] = "GR";
  CountryCode["GS"] = "GS";
  CountryCode["GT"] = "GT";
  CountryCode["GU"] = "GU";
  CountryCode["GW"] = "GW";
  CountryCode["GY"] = "GY";
  CountryCode["HK"] = "HK";
  CountryCode["HM"] = "HM";
  CountryCode["HN"] = "HN";
  CountryCode["HR"] = "HR";
  CountryCode["HT"] = "HT";
  CountryCode["HU"] = "HU";
  CountryCode["ID"] = "ID";
  CountryCode["IE"] = "IE";
  CountryCode["IL"] = "IL";
  CountryCode["IM"] = "IM";
  CountryCode["IN"] = "IN";
  CountryCode["IO"] = "IO";
  CountryCode["IQ"] = "IQ";
  CountryCode["IR"] = "IR";
  CountryCode["IS"] = "IS";
  CountryCode["IT"] = "IT";
  CountryCode["JE"] = "JE";
  CountryCode["JM"] = "JM";
  CountryCode["JO"] = "JO";
  CountryCode["JP"] = "JP";
  CountryCode["KE"] = "KE";
  CountryCode["KG"] = "KG";
  CountryCode["KH"] = "KH";
  CountryCode["KI"] = "KI";
  CountryCode["KM"] = "KM";
  CountryCode["KN"] = "KN";
  CountryCode["KP"] = "KP";
  CountryCode["KR"] = "KR";
  CountryCode["KW"] = "KW";
  CountryCode["KY"] = "KY";
  CountryCode["KZ"] = "KZ";
  CountryCode["LA"] = "LA";
  CountryCode["LB"] = "LB";
  CountryCode["LC"] = "LC";
  CountryCode["LI"] = "LI";
  CountryCode["LK"] = "LK";
  CountryCode["LR"] = "LR";
  CountryCode["LS"] = "LS";
  CountryCode["LT"] = "LT";
  CountryCode["LU"] = "LU";
  CountryCode["LV"] = "LV";
  CountryCode["LY"] = "LY";
  CountryCode["MA"] = "MA";
  CountryCode["MC"] = "MC";
  CountryCode["MD"] = "MD";
  CountryCode["ME"] = "ME";
  CountryCode["MF"] = "MF";
  CountryCode["MG"] = "MG";
  CountryCode["MH"] = "MH";
  CountryCode["MK"] = "MK";
  CountryCode["ML"] = "ML";
  CountryCode["MM"] = "MM";
  CountryCode["MN"] = "MN";
  CountryCode["MO"] = "MO";
  CountryCode["MP"] = "MP";
  CountryCode["MQ"] = "MQ";
  CountryCode["MR"] = "MR";
  CountryCode["MS"] = "MS";
  CountryCode["MT"] = "MT";
  CountryCode["MU"] = "MU";
  CountryCode["MV"] = "MV";
  CountryCode["MW"] = "MW";
  CountryCode["MX"] = "MX";
  CountryCode["MY"] = "MY";
  CountryCode["MZ"] = "MZ";
  CountryCode["NA"] = "NA";
  CountryCode["NC"] = "NC";
  CountryCode["NE"] = "NE";
  CountryCode["NF"] = "NF";
  CountryCode["NG"] = "NG";
  CountryCode["NI"] = "NI";
  CountryCode["NL"] = "NL";
  CountryCode["NO"] = "NO";
  CountryCode["NP"] = "NP";
  CountryCode["NR"] = "NR";
  CountryCode["NU"] = "NU";
  CountryCode["NZ"] = "NZ";
  CountryCode["OM"] = "OM";
  CountryCode["PA"] = "PA";
  CountryCode["PE"] = "PE";
  CountryCode["PF"] = "PF";
  CountryCode["PG"] = "PG";
  CountryCode["PH"] = "PH";
  CountryCode["PK"] = "PK";
  CountryCode["PL"] = "PL";
  CountryCode["PM"] = "PM";
  CountryCode["PN"] = "PN";
  CountryCode["PR"] = "PR";
  CountryCode["PS"] = "PS";
  CountryCode["PT"] = "PT";
  CountryCode["PW"] = "PW";
  CountryCode["PY"] = "PY";
  CountryCode["QA"] = "QA";
  CountryCode["RE"] = "RE";
  CountryCode["RO"] = "RO";
  CountryCode["RS"] = "RS";
  CountryCode["RU"] = "RU";
  CountryCode["RW"] = "RW";
  CountryCode["SA"] = "SA";
  CountryCode["SB"] = "SB";
  CountryCode["SC"] = "SC";
  CountryCode["SD"] = "SD";
  CountryCode["SE"] = "SE";
  CountryCode["SG"] = "SG";
  CountryCode["SH"] = "SH";
  CountryCode["SI"] = "SI";
  CountryCode["SJ"] = "SJ";
  CountryCode["SK"] = "SK";
  CountryCode["SL"] = "SL";
  CountryCode["SM"] = "SM";
  CountryCode["SN"] = "SN";
  CountryCode["SO"] = "SO";
  CountryCode["SR"] = "SR";
  CountryCode["SS"] = "SS";
  CountryCode["ST"] = "ST";
  CountryCode["SV"] = "SV";
  CountryCode["SX"] = "SX";
  CountryCode["SY"] = "SY";
  CountryCode["SZ"] = "SZ";
  CountryCode["TC"] = "TC";
  CountryCode["TD"] = "TD";
  CountryCode["TF"] = "TF";
  CountryCode["TG"] = "TG";
  CountryCode["TH"] = "TH";
  CountryCode["TJ"] = "TJ";
  CountryCode["TK"] = "TK";
  CountryCode["TL"] = "TL";
  CountryCode["TM"] = "TM";
  CountryCode["TN"] = "TN";
  CountryCode["TO"] = "TO";
  CountryCode["TR"] = "TR";
  CountryCode["TT"] = "TT";
  CountryCode["TV"] = "TV";
  CountryCode["TW"] = "TW";
  CountryCode["TZ"] = "TZ";
  CountryCode["UA"] = "UA";
  CountryCode["UG"] = "UG";
  CountryCode["UM"] = "UM";
  CountryCode["US"] = "US";
  CountryCode["UY"] = "UY";
  CountryCode["UZ"] = "UZ";
  CountryCode["VA"] = "VA";
  CountryCode["VC"] = "VC";
  CountryCode["VE"] = "VE";
  CountryCode["VG"] = "VG";
  CountryCode["VI"] = "VI";
  CountryCode["VN"] = "VN";
  CountryCode["VU"] = "VU";
  CountryCode["WF"] = "WF";
  CountryCode["WS"] = "WS";
  CountryCode["YE"] = "YE";
  CountryCode["YT"] = "YT";
  CountryCode["ZA"] = "ZA";
  CountryCode["ZM"] = "ZM";
  CountryCode["ZW"] = "ZW";
})(CountryCode || (CountryCode = {}));

export var FulfillmentStatus;
/**
 * An enumeration.
 */

(function (FulfillmentStatus) {
  FulfillmentStatus["CANCELED"] = "CANCELED";
  FulfillmentStatus["FULFILLED"] = "FULFILLED";
})(FulfillmentStatus || (FulfillmentStatus = {}));

export var JobStatusEnum;

(function (JobStatusEnum) {
  JobStatusEnum["DELETED"] = "DELETED";
  JobStatusEnum["FAILED"] = "FAILED";
  JobStatusEnum["PENDING"] = "PENDING";
  JobStatusEnum["SUCCESS"] = "SUCCESS";
})(JobStatusEnum || (JobStatusEnum = {}));

export var OrderAction;

(function (OrderAction) {
  OrderAction["CAPTURE"] = "CAPTURE";
  OrderAction["MARK_AS_PAID"] = "MARK_AS_PAID";
  OrderAction["REFUND"] = "REFUND";
  OrderAction["VOID"] = "VOID";
})(OrderAction || (OrderAction = {}));

export var OrderDirection;
/**
 * An enumeration.
 */

(function (OrderDirection) {
  OrderDirection["ASC"] = "ASC";
  OrderDirection["DESC"] = "DESC";
})(OrderDirection || (OrderDirection = {}));

export var OrderErrorCode;
/**
 * An enumeration.
 */

(function (OrderErrorCode) {
  OrderErrorCode["BILLING_ADDRESS_NOT_SET"] = "BILLING_ADDRESS_NOT_SET";
  OrderErrorCode["CANNOT_CANCEL_FULFILLMENT"] = "CANNOT_CANCEL_FULFILLMENT";
  OrderErrorCode["CANNOT_CANCEL_ORDER"] = "CANNOT_CANCEL_ORDER";
  OrderErrorCode["CANNOT_DELETE"] = "CANNOT_DELETE";
  OrderErrorCode["CANNOT_REFUND"] = "CANNOT_REFUND";
  OrderErrorCode["CAPTURE_INACTIVE_PAYMENT"] = "CAPTURE_INACTIVE_PAYMENT";
  OrderErrorCode["DUPLICATED_INPUT_ITEM"] = "DUPLICATED_INPUT_ITEM";
  OrderErrorCode["FULFILL_ORDER_LINE"] = "FULFILL_ORDER_LINE";
  OrderErrorCode["GRAPHQL_ERROR"] = "GRAPHQL_ERROR";
  OrderErrorCode["INSUFFICIENT_STOCK"] = "INSUFFICIENT_STOCK";
  OrderErrorCode["INVALID"] = "INVALID";
  OrderErrorCode["NOT_EDITABLE"] = "NOT_EDITABLE";
  OrderErrorCode["NOT_FOUND"] = "NOT_FOUND";
  OrderErrorCode["ORDER_NO_SHIPPING_ADDRESS"] = "ORDER_NO_SHIPPING_ADDRESS";
  OrderErrorCode["PAYMENT_ERROR"] = "PAYMENT_ERROR";
  OrderErrorCode["PAYMENT_MISSING"] = "PAYMENT_MISSING";
  OrderErrorCode["PRODUCT_NOT_PUBLISHED"] = "PRODUCT_NOT_PUBLISHED";
  OrderErrorCode["PRODUCT_UNAVAILABLE_FOR_PURCHASE"] = "PRODUCT_UNAVAILABLE_FOR_PURCHASE";
  OrderErrorCode["REQUIRED"] = "REQUIRED";
  OrderErrorCode["SHIPPING_METHOD_NOT_APPLICABLE"] = "SHIPPING_METHOD_NOT_APPLICABLE";
  OrderErrorCode["SHIPPING_METHOD_REQUIRED"] = "SHIPPING_METHOD_REQUIRED";
  OrderErrorCode["TAX_ERROR"] = "TAX_ERROR";
  OrderErrorCode["UNIQUE"] = "UNIQUE";
  OrderErrorCode["VOID_INACTIVE_PAYMENT"] = "VOID_INACTIVE_PAYMENT";
  OrderErrorCode["ZERO_QUANTITY"] = "ZERO_QUANTITY";
})(OrderErrorCode || (OrderErrorCode = {}));

export var OrderEventsEmailsEnum;
/**
 * An enumeration.
 */

(function (OrderEventsEmailsEnum) {
  OrderEventsEmailsEnum["DIGITAL_LINKS"] = "DIGITAL_LINKS";
  OrderEventsEmailsEnum["FULFILLMENT_CONFIRMATION"] = "FULFILLMENT_CONFIRMATION";
  OrderEventsEmailsEnum["ORDER_CANCEL"] = "ORDER_CANCEL";
  OrderEventsEmailsEnum["ORDER_CONFIRMATION"] = "ORDER_CONFIRMATION";
  OrderEventsEmailsEnum["ORDER_REFUND"] = "ORDER_REFUND";
  OrderEventsEmailsEnum["PAYMENT_CONFIRMATION"] = "PAYMENT_CONFIRMATION";
  OrderEventsEmailsEnum["SHIPPING_CONFIRMATION"] = "SHIPPING_CONFIRMATION";
  OrderEventsEmailsEnum["TRACKING_UPDATED"] = "TRACKING_UPDATED";
})(OrderEventsEmailsEnum || (OrderEventsEmailsEnum = {}));

export var OrderEventsEnum;
/**
 * An enumeration.
 */

(function (OrderEventsEnum) {
  OrderEventsEnum["CANCELED"] = "CANCELED";
  OrderEventsEnum["DRAFT_ADDED_PRODUCTS"] = "DRAFT_ADDED_PRODUCTS";
  OrderEventsEnum["DRAFT_CREATED"] = "DRAFT_CREATED";
  OrderEventsEnum["DRAFT_REMOVED_PRODUCTS"] = "DRAFT_REMOVED_PRODUCTS";
  OrderEventsEnum["EMAIL_SENT"] = "EMAIL_SENT";
  OrderEventsEnum["EXTERNAL_SERVICE_NOTIFICATION"] = "EXTERNAL_SERVICE_NOTIFICATION";
  OrderEventsEnum["FULFILLMENT_CANCELED"] = "FULFILLMENT_CANCELED";
  OrderEventsEnum["FULFILLMENT_FULFILLED_ITEMS"] = "FULFILLMENT_FULFILLED_ITEMS";
  OrderEventsEnum["FULFILLMENT_RESTOCKED_ITEMS"] = "FULFILLMENT_RESTOCKED_ITEMS";
  OrderEventsEnum["INVOICE_GENERATED"] = "INVOICE_GENERATED";
  OrderEventsEnum["INVOICE_REQUESTED"] = "INVOICE_REQUESTED";
  OrderEventsEnum["INVOICE_SENT"] = "INVOICE_SENT";
  OrderEventsEnum["INVOICE_UPDATED"] = "INVOICE_UPDATED";
  OrderEventsEnum["NOTE_ADDED"] = "NOTE_ADDED";
  OrderEventsEnum["ORDER_FULLY_PAID"] = "ORDER_FULLY_PAID";
  OrderEventsEnum["ORDER_MARKED_AS_PAID"] = "ORDER_MARKED_AS_PAID";
  OrderEventsEnum["OTHER"] = "OTHER";
  OrderEventsEnum["OVERSOLD_ITEMS"] = "OVERSOLD_ITEMS";
  OrderEventsEnum["PAYMENT_AUTHORIZED"] = "PAYMENT_AUTHORIZED";
  OrderEventsEnum["PAYMENT_CAPTURED"] = "PAYMENT_CAPTURED";
  OrderEventsEnum["PAYMENT_FAILED"] = "PAYMENT_FAILED";
  OrderEventsEnum["PAYMENT_REFUNDED"] = "PAYMENT_REFUNDED";
  OrderEventsEnum["PAYMENT_VOIDED"] = "PAYMENT_VOIDED";
  OrderEventsEnum["PLACED"] = "PLACED";
  OrderEventsEnum["PLACED_FROM_DRAFT"] = "PLACED_FROM_DRAFT";
  OrderEventsEnum["TRACKING_UPDATED"] = "TRACKING_UPDATED";
  OrderEventsEnum["UPDATED_ADDRESS"] = "UPDATED_ADDRESS";
})(OrderEventsEnum || (OrderEventsEnum = {}));

export var OrderStatus;
/**
 * An enumeration.
 */

(function (OrderStatus) {
  OrderStatus["CANCELED"] = "CANCELED";
  OrderStatus["DRAFT"] = "DRAFT";
  OrderStatus["FULFILLED"] = "FULFILLED";
  OrderStatus["PARTIALLY_FULFILLED"] = "PARTIALLY_FULFILLED";
  OrderStatus["UNFULFILLED"] = "UNFULFILLED";
})(OrderStatus || (OrderStatus = {}));

export var PaymentChargeStatusEnum;

(function (PaymentChargeStatusEnum) {
  PaymentChargeStatusEnum["CANCELLED"] = "CANCELLED";
  PaymentChargeStatusEnum["FULLY_CHARGED"] = "FULLY_CHARGED";
  PaymentChargeStatusEnum["FULLY_REFUNDED"] = "FULLY_REFUNDED";
  PaymentChargeStatusEnum["NOT_CHARGED"] = "NOT_CHARGED";
  PaymentChargeStatusEnum["PARTIALLY_CHARGED"] = "PARTIALLY_CHARGED";
  PaymentChargeStatusEnum["PARTIALLY_REFUNDED"] = "PARTIALLY_REFUNDED";
  PaymentChargeStatusEnum["PENDING"] = "PENDING";
  PaymentChargeStatusEnum["REFUSED"] = "REFUSED";
})(PaymentChargeStatusEnum || (PaymentChargeStatusEnum = {}));

export var ProductOrderField;

(function (ProductOrderField) {
  ProductOrderField["COLLECTION"] = "COLLECTION";
  ProductOrderField["DATE"] = "DATE";
  ProductOrderField["MINIMAL_PRICE"] = "MINIMAL_PRICE";
  ProductOrderField["NAME"] = "NAME";
  ProductOrderField["PRICE"] = "PRICE";
  ProductOrderField["PUBLICATION_DATE"] = "PUBLICATION_DATE";
  ProductOrderField["PUBLISHED"] = "PUBLISHED";
  ProductOrderField["TYPE"] = "TYPE";
})(ProductOrderField || (ProductOrderField = {})); //==============================================================
// END Enums and Input Objects
//==============================================================