import "./scss/index.scss";
import * as React from "react";
import Nav from "./Nav";

var Footer = function Footer() {
  return React.createElement("div", {
    className: "footer",
    id: "footer"
  }, React.createElement(Nav, null));
};

export default Footer;