import "./scss/index.scss";
import { CategorySortField, OrderDirection } from "gqlTypes/globalTypes";
import * as React from "react";
import { getBackgroundImageSizeForCategory } from "@temp/core/config";
import { MetaWrapper } from "../../components";
import Page from "./Page";
import { TypedHomePageQuery } from "./queries";

var View = function View() {
  var variables = {
    sortBy: {
      field: CategorySortField.TREE_ID,
      direction: OrderDirection.ASC
    },
    backgroundImage: getBackgroundImageSizeForCategory()
  };
  return React.createElement("div", {
    className: "home-page"
  }, React.createElement(TypedHomePageQuery, {
    variables: variables,
    alwaysRender: true,
    displayLoader: false,
    errorPolicy: "all"
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(MetaWrapper, {
      meta: {
        description: data.shop ? data.shop.description : "",
        title: data.shop ? data.shop.name : ""
      }
    }, React.createElement(Page, {
      loading: loading,
      backgroundImage: data.shop && data.shop.homepageCollection && data.shop.homepageCollection.backgroundImage,
      categories: data.categories,
      shop: data.shop
    }));
  }));
};

export default View;