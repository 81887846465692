import React from "react";
export var JNPLContactNumber = function JNPLContactNumber(_ref) {
  var showAllContactNumbers = _ref.showAllContactNumbers;
  return React.createElement("span", null, React.createElement("a", {
    href: "tel:+917080825825",
    style: {
      color: "#b93a3e",
      textDecoration: "underline"
    }
  }, "Call"), " ", "or", " ", React.createElement("a", {
    target: "_blank",
    rel: "noopener noreferrer",
    href: "https://api.whatsapp.com/send?phone=+917080825825&lang=en",
    style: {
      color: "#b93a3e",
      textDecoration: "underline"
    }
  }, "Whatsapp"), " ", "us on", " ", React.createElement("a", {
    href: "tel:+917080825825",
    style: {
      color: "#b93a3e",
      textDecoration: "underline"
    }
  }, "+917080825825"), showAllContactNumbers && React.createElement(React.Fragment, null, "\xA0/\xA0", React.createElement("a", {
    href: "tel:+919580509344",
    style: {
      color: "#b93a3e",
      textDecoration: "underline"
    }
  }, "+919580509344")));
};