import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject17() {
  var data = _taggedTemplateLiteral(["\n  p {\n  font-size: 0.8rem\n  line-height: 1rem\n  }\n\n"]);

  _templateObject17 = function _templateObject17() {
    return data;
  };

  return data;
}

function _templateObject16() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject16 = function _templateObject16() {
    return data;
  };

  return data;
}

function _templateObject15() {
  var data = _taggedTemplateLiteral(["\n  height: 3rem;\n  margin: 0 0 0.2rem 0;\n  padding-top: 0.3rem;\n  "]);

  _templateObject15 = function _templateObject15() {
    return data;
  };

  return data;
}

function _templateObject14() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  margin-top: 20px;\n\n  ", "\n\n  .react-select-wrapper,\n  .input {\n    width: 50%;\n    margin-bottom: 1rem;\n  }\n"]);

  _templateObject14 = function _templateObject14() {
    return data;
  };

  return data;
}

function _templateObject13() {
  var data = _taggedTemplateLiteral(["\n  padding-top: 0.8rem;\n"]);

  _templateObject13 = function _templateObject13() {
    return data;
  };

  return data;
}

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", "\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n  > img {\n    width: 100%;\n    height: auto;\n    max-width: 100%;\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  text-decoration: line-through;\n  color: ", ";\n  font-size: ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 0.3rem;\n  font-size: 0.8rem\n\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  margin-bottom: ", ";\n  text-align: left;\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    margin-bottom: 0;\n    font-size: 0.7rem\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  text-transform: uppercase;\n  font-weight: normal;\n  ", "\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    max-height: 25rem;\n    width: calc(100vw / 2 - 1.25rem);\n    padding: 0.75rem;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    padding: 1.8rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background: ", ";\n  padding: 1rem;\n  text-align: center;\n  max-height: 40rem;\n  transition: 0.3s;\n  :hover {\n    background-color: ", ";\n  }\n\n  ", "\n\n  ", "\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0 0 0.5rem 0;\n  text-align: left;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
import { css } from "styled-components";
var textProps = css(_templateObject(), function (props) {
  return props.theme.typography.baseFontSize;
});
export var Wrapper = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.hoverLightBackground;
}, media.largeScreen(_templateObject3()), media.smallScreen(_templateObject4()));
export var Title = styled.h4(_templateObject5(), textProps, media.smallScreen(_templateObject6()));
export var Price = styled.p(_templateObject7(), textProps);
export var ProductPricing = styled.h4(_templateObject8(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject9()));
export var UndiscountedPrice = styled.span(_templateObject10(), function (props) {
  return props.theme.colors.baseFontColorSemiTransparent;
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var Image = styled.div(_templateObject11());
export var ErrorMessage = styled.p(_templateObject12(), function (props) {
  return props.theme.colors.error;
}, media.smallScreen(_templateObject13()));
export var VariantPicker = styled.div(_templateObject14(), media.smallScreen(_templateObject15()));
export var StockNotification = styled.div(_templateObject16(), media.smallScreen(_templateObject17()));