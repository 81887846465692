import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n      font-size: 0.75rem ;\n      padding: 0 0.2rem;\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n      height: 1.6rem;\n\n      span {\n        font-size: 0.8rem;\n      }\n    "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n      font-size: 1rem\n    "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n      height: 1.6rem;\n      width: 1.6rem;\n    "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    height: 1.6rem;\n    font-size: 0.8rem;\n    margin-bottom: 0.2rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 5px;\n  margin-bottom: 5px;\n  text-align: center;\n  font-size: 20px;\n\n  ", "\n\n  .quantityChangeRow {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    align-self: center;\n    justify-self: center;\n  }\n\n  .quantityChangeButton {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n\n    height: 3rem;\n    width: 3rem;\n    \n    ", "\n  }\n\n  .quantityChangeButtonFontSize{\n    font-size: 2rem\n    line-height: 100%\n\n    ", "\n  }\n\n  .addToCartNew {\n    display: inline-flex;\n    align-items: center;\n    justify-content: center;\n\n    height: 3rem;\n    ", "\n  }\n\n  .quantity{\n    color: black;\n    padding: 0 1rem;\n   \n    ", "\n  }\n\n  &[hidden] {\n    display: none !important;\n  }\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var JnplAddToCartButton = styled.div(_templateObject(), media.smallScreen(_templateObject2()), media.smallScreen(_templateObject3()), media.smallScreen(_templateObject4()), media.smallScreen(_templateObject5()), media.smallScreen(_templateObject6()));