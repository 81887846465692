import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { getBackgroundColor } from "@utils/styles";
import { InputLabel } from "../InputLabel";
import * as S from "./styles";
export var Input = function Input(_ref) {
  var onBlur = _ref.onBlur,
      onFocus = _ref.onFocus,
      _ref$contentLeft = _ref.contentLeft,
      contentLeft = _ref$contentLeft === void 0 ? null : _ref$contentLeft,
      _ref$contentRight = _ref.contentRight,
      contentRight = _ref$contentRight === void 0 ? null : _ref$contentRight,
      _ref$error = _ref.error,
      error = _ref$error === void 0 ? false : _ref$error,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      placeholder = _ref.placeholder,
      label = _ref.label,
      value = _ref.value,
      onChange = _ref.onChange,
      props = _objectWithoutProperties(_ref, ["onBlur", "onFocus", "contentLeft", "contentRight", "error", "disabled", "placeholder", "label", "value", "onChange"]);

  var elementRef = React.useRef(null);

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      active = _React$useState2[0],
      setActive = _React$useState2[1];

  var _React$useState3 = React.useState("transparent"),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      labelBackground = _React$useState4[0],
      setColor = _React$useState4[1];

  React.useEffect(function () {
    if (elementRef) {
      var color = getBackgroundColor(elementRef.current);
      setColor(color);
    }
  }, []);
  var handleFocus = React.useCallback(function (e) {
    setActive(true);

    if (onFocus) {
      onFocus(e);
    }
  }, [setActive, onFocus]);
  var handleBlur = React.useCallback(function (e) {
    setActive(false);

    if (onBlur) {
      onBlur(e);
    }
  }, [setActive, onBlur]);
  return React.createElement(S.Wrapper, {
    active: active,
    error: error,
    disabled: disabled,
    ref: elementRef
  }, contentLeft && React.createElement(S.Content, null, contentLeft), React.createElement(S.InputWrapper, null, React.createElement(S.Input, _extends({}, props, {
    value: value,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled: disabled,
    onChange: onChange,
    labelBackground: labelBackground
  })), label && React.createElement(InputLabel, {
    labelBackground: labelBackground,
    active: active || !!value || !!error
  }, label)), contentRight && React.createElement(S.Content, null, contentRight));
};