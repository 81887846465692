import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "../globalStyles/scss/index.scss";
import React, { useEffect, useState } from "react";
import { Loader } from "@components/atoms";
import { useAuth } from "@saleor/sdk";
import { demoMode } from "@temp/constants";
import { Footer, MainMenu, MetaConsumer, OverlayManager, OverlayProvider } from "../components";
import ShopProvider from "../components/ShopProvider";
import Notifications from "./Notifications";
import { Routes } from "./routes";

var App = function App() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      fixedNavbar = _useState2[0],
      setFixedNavbar = _useState2[1];

  var _useAuth = useAuth(),
      tokenRefreshing = _useAuth.tokenRefreshing,
      tokenVerifying = _useAuth.tokenVerifying;

  useEffect(function () {
    var lastScrollTop = 0;
    var scrollHandlerTimeoutId;

    var scrollHandler = function scrollHandler() {
      if (scrollHandlerTimeoutId) {
        clearTimeout(scrollHandlerTimeoutId);
      }

      scrollHandlerTimeoutId = setTimeout(function () {
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setFixedNavbar(scrollTop > 120 && scrollTop < lastScrollTop);
        lastScrollTop = scrollTop;
      }, 200);
    };

    window.addEventListener("scroll", scrollHandler);
    return function () {
      return window.removeEventListener("scroll", scrollHandler);
    };
  }, [setFixedNavbar]);

  if (tokenRefreshing || tokenVerifying) {
    return React.createElement(Loader, null);
  }

  return React.createElement(ShopProvider, null, React.createElement(OverlayProvider, null, React.createElement(MetaConsumer, null), React.createElement(MainMenu, {
    isFixedNavBar: fixedNavbar,
    demoMode: demoMode
  }), React.createElement("div", {
    style: {
      marginTop: fixedNavbar ? function () {
        var header = document.querySelector("header");
        return header ? "".concat(header.clientHeight, "px") : "5.55rem";
      }() : 0
    }
  }, React.createElement(Routes, null)), React.createElement(Footer, null), React.createElement(OverlayManager, null), React.createElement(Notifications, null)));
};

export default App;