import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  query CategoryProducts(\n    $id: ID!\n    $attributes: [AttributeInput]\n    $after: String\n    $pageSize: Int\n    $sortBy: ProductOrder\n    $priceLte: Float\n    $priceGte: Float\n    $countryCode: CountryCode\n    $thumbnailSize: Int = 255\n    $thumbnail2xSize: Int = 510\n  ) {\n    products(\n      after: $after\n      first: $pageSize\n      sortBy: $sortBy\n      filter: {\n        attributes: $attributes\n        categories: [$id]\n        minimalPrice: { gte: $priceGte, lte: $priceLte }\n      }\n    ) {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          thumbnail(size: $thumbnailSize) {\n            url\n            alt\n          }\n          thumbnail2x: thumbnail(size: $thumbnail2xSize) {\n            url\n          }\n          ...ProductPricingField\n          category {\n            id\n            name\n          }\n          attributes {\n            ...SelectedAttributeFields\n          }\n          variants {\n            ...ProductVariantFields\n          }\n          isAvailable\n          isAvailableForPurchase\n          availableForPurchase\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query Category($id: ID!, $backgroundImageSize: Int!) {\n    category(id: $id) {\n      seoDescription\n      seoTitle\n      id\n      name\n      backgroundImage(size: $backgroundImageSize) {\n        url\n      }\n      ancestors(last: 5) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n    attributes(\n      filter: { inCategory: $id, filterableInStorefront: true }\n      first: 100\n    ) {\n      edges {\n        node {\n          id\n          name\n          slug\n          values {\n            id\n            name\n            slug\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
import { productPricingFragment, productVariantFragment, selectedAttributeFragment } from "../Product/queries";
export var categoryProductsDataQuery = gql(_templateObject());
export var TypedCategoryProductsDataQuery = TypedQuery(categoryProductsDataQuery);
export var categoryProductsQuery = gql(_templateObject2(), selectedAttributeFragment, productVariantFragment, productPricingFragment);
export var TypedCategoryProductsQuery = TypedQuery(categoryProductsQuery);