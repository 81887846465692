import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Loader } from "@components/atoms";
import { JnplProductTile } from "../../molecules/ProductTile/ProductTile";
import * as S from "./styles";
export var JnplProductList = function JnplProductList(_ref) {
  var products = _ref.products,
      _ref$canLoadMore = _ref.canLoadMore,
      canLoadMore = _ref$canLoadMore === void 0 ? false : _ref$canLoadMore,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      testingContextId = _ref.testingContextId,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler,
      _ref$onLoadMore = _ref.onLoadMore,
      onLoadMore = _ref$onLoadMore === void 0 ? function () {
    return null;
  } : _ref$onLoadMore,
      cartItems = _ref.cartItems,
      addItemToCart = _ref.addItemToCart;
  return React.createElement(React.Fragment, null, React.createElement(S.List, {
    "data-test": "productList",
    "data-test-id": testingContextId
  }, products.map(function (product) {
    var id = product.id,
        name = product.name;
    return id && name && React.createElement(JnplProductTile, {
      product: product,
      queryAttributes: queryAttributes,
      onAttributeChangeHandler: onAttributeChangeHandler,
      cartItems: cartItems,
      addItemToCart: addItemToCart
    });
  })), React.createElement(S.Loader, null, loading ? React.createElement(Loader, null) : canLoadMore && React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    color: "secondary",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "@jnpl.components.organisms.ProductList.ProductList.2783099424",
    defaultMessage: "More +"
  }))));
};