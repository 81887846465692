import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    min-width: 150px;\n    margin-right: 20px;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  cursor: pointer;\n  min-width: 230px;\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.02em;\n  color: ", ";\n  border-bottom-width: 4px;\n  border-bottom-style: solid;\n  border-bottom-color: ", ";\n  padding-bottom: 25px;\n  margin-right: 60px;\n\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: none;\n  width: 100%;\n  border-bottom: 1px solid ", ";\n  margin-bottom: 70px;\n  overflow: hidden;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    column-width: 100%;\n    columns: 1;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  columns: 2;\n  column-width: 50%;\n\n  ", ";\n  width: 100%;\n  padding: 0;\n  margin: 0;\n\n  li {\n    margin-bottom: 30px;\n    font-size: ", ";\n  }\n\n  li::before {\n    content: \"\u2022\";\n    margin-right: 20px;\n    color: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  ul,\n  ol {\n    list-style: initial !important;\n    padding-left: 1rem;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var AttributeList = styled.ul(_templateObject2(), media.largeScreen(_templateObject3()), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.listBullet;
});
export var Tabs = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.tabsBorder;
});
export var TabTitle = styled.div(_templateObject5(), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.active && props.theme.colors.tabTitle;
}, function (props) {
  return props.active ? props.theme.colors.tabTitle : "transparent";
}, media.smallScreen(_templateObject6(), function (props) {
  return props.theme.typography.h4FontSize;
}));
export var AttributeName = styled.span(_templateObject7(), function (props) {
  return props.theme.colors.listAttributeName;
});