import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: 0.8rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  font-size: 1rem;\n  background: ", ";\n  color: ", ";\n  font-weight: bold;\n  line-height: 1.3rem;\n  padding: 2px;\n  height: fit-content;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var JNPLNotification = styled.div(_templateObject(), function (props) {
  return props.theme.colors.secondary;
}, function (props) {
  return props.theme.colors.white;
}, media.smallScreen(_templateObject2()));