import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Checkbox } from "@components/atoms";
import { TERMS_AND_CONDITIONS_PAGE_URL } from "@temp/core/config";
import { accountConfirmUrl } from "../../../app/routes";
import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { TypedAccountRegisterMutation } from "./queries";

var showSuccessNotification = function showSuccessNotification(data, hide, alert, intl) {
  var successful = maybe(function () {
    return !data.accountRegister.errors.length;
  });

  if (successful) {
    hide();
    alert.show({
      title: data.accountRegister.requiresConfirmation ? intl.formatMessage({
        defaultMessage: "Please check your e-mail for further instructions",
        "id": "components.OverlayManager.Login.RegisterForm.2622394113"
      }) : intl.formatMessage({
        defaultMessage: "New user has been created",
        "id": "components.OverlayManager.Login.RegisterForm.590842025"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  }
};

var RegisterForm = function RegisterForm(_ref) {
  var hide = _ref.hide;
  var alert = useAlert();
  var intl = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      termsOfServiceAccpeted = _React$useState2[0],
      acceptTermsOFService = _React$useState2[1];

  return React.createElement(TypedAccountRegisterMutation, {
    onCompleted: function onCompleted(data) {
      return showSuccessNotification(data, hide, alert, intl);
    }
  }, function (registerCustomer, _ref2) {
    var loading = _ref2.loading,
        data = _ref2.data;
    return React.createElement(Form, {
      errors: maybe(function () {
        return data.accountRegister.errors;
      }, []),
      onSubmit: function onSubmit(event, _ref3) {
        var email = _ref3.email,
            password = _ref3.password;
        event.preventDefault();

        if (termsOfServiceAccpeted) {
          var redirectUrl = "".concat(window.location.origin).concat(accountConfirmUrl);
          registerCustomer({
            variables: {
              email: email,
              password: password,
              redirectUrl: redirectUrl
            }
          });
        }
      }
    }, React.createElement(TextField, {
      name: "email",
      autoComplete: "email",
      label: intl.formatMessage(commonMessages.eMail),
      type: "email",
      required: true
    }), React.createElement(TextField, {
      name: "password",
      autoComplete: "password",
      label: intl.formatMessage(commonMessages.password),
      type: "password",
      required: true
    }), React.createElement(Checkbox, {
      "data-test": "registrationForm",
      name: "registration-form",
      checked: termsOfServiceAccpeted,
      onChange: function onChange() {
        return acceptTermsOFService(!termsOfServiceAccpeted);
      },
      required: true,
      errorMessage: "Please accept the terms and conditions of our service"
    }, "I agree to the\xA0", React.createElement(Link, {
      to: TERMS_AND_CONDITIONS_PAGE_URL,
      style: {
        color: "#b93a3e",
        textDecoration: "underline"
      },
      onClick: function onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        window.open(TERMS_AND_CONDITIONS_PAGE_URL, "_blank");
      }
    }, "Terms and Conditions")), React.createElement("div", {
      className: "login__content__button"
    }, React.createElement(Button, _extends({
      testingContext: "submitRegisterFormButton",
      type: "submit"
    }, loading && {
      disabled: true
    }), loading ? intl.formatMessage(commonMessages.loading) : intl.formatMessage(_defineProperty({
      defaultMessage: "Register",
      "id": "components.OverlayManager.Login.RegisterForm.2556234295"
    }, "id", "components.OverlayManager.Login.RegisterForm.2556234295")))));
  });
};

export default RegisterForm;