import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { Formik } from "formik";
import { pick } from "lodash";
import React from "react";
import * as Yup from "yup";
import { INDIAN_MOBILE_NUMBER_PATTERN } from "@jnpl/helpers/mobile-numbers";
import { AddressFormContent } from "./AddressFormContent";
var ADDRESS_FIELDS = ["city", "companyName", "countryArea", "firstName", "lastName", "country", "phone", "postalCode", "streetAddress1", "streetAddress2", "email"];
export var AddressForm = function AddressForm(_ref) {
  var address = _ref.address,
      handleSubmit = _ref.handleSubmit,
      formId = _ref.formId,
      defaultValue = _ref.defaultValue,
      countriesOptions = _ref.countriesOptions,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? false : _ref$includeEmail,
      props = _objectWithoutProperties(_ref, ["address", "handleSubmit", "formId", "defaultValue", "countriesOptions", "includeEmail"]);

  var addressWithPickedFields = {};

  if (address) {
    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
  }

  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  var yupFields = {
    firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    companyName: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    phone: Yup.string().matches(INDIAN_MOBILE_NUMBER_PATTERN, "It should a valid 10-digit Indian mobile number").required("Required"),
    streetAddress1: Yup.string().min(2, "Too Short!").required("Required"),
    city: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    countryArea: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    country: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
    postalCode: Yup.string().matches(/^\d{6}$/, "Postal code is not valid").required("Required")
  };

  if (includeEmail) {
    yupFields.email = Yup.string().email("Invalid email").required("Required");
  }

  var addressSchema = Yup.object().shape(yupFields);
  return React.createElement(Formik, {
    initialValues: addressWithPickedFields,
    validationSchema: addressSchema,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (handleSubmit) {
        handleSubmit(values);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched,
        validateForm = _ref3.validateForm;
    return React.createElement(AddressFormContent, _extends({
      countriesOptions: countriesOptions,
      defaultValue: defaultValue,
      formId: formId,
      handleBlur: handleBlur,
      handleChange: handleChange,
      handleSubmit: handleSubmit,
      setFieldTouched: setFieldTouched,
      setFieldValue: setFieldValue,
      values: values,
      includeEmail: includeEmail,
      validateForm: validateForm
    }, props));
  });
};