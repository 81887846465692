import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { Thumbnail } from "@components/molecules";
import { canAddToCart, getAvailableQuantity, getProductPrice } from "@components/organisms";
import ProductVariantPicker from "@components/organisms/ProductVariantPicker";
import { generateProductUrl } from "@temp/core/utils";
import { commonMessages } from "@temp/intl";
import { AddToCartButton } from "../AddToCartButton";
import * as S from "./styles";
var LOW_STOCK_QUANTITY = 2;
export var JnplProductTile = function JnplProductTile(_ref) {
  var _product$variants, _product$variants$;

  var product = _ref.product,
      queryAttributes = _ref.queryAttributes,
      cartItems = _ref.cartItems,
      addItemToCart = _ref.addItemToCart;
  var intl = useIntl();
  var detailPageLink = generateProductUrl(product.id, product.name);

  var _React$useState = React.useState(((_product$variants = product.variants) === null || _product$variants === void 0 ? void 0 : (_product$variants$ = _product$variants[0]) === null || _product$variants$ === void 0 ? void 0 : _product$variants$.id) || ""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1]; // Let's set a default value for variant stock for initial rendering when setVariantStock hasn't been called yet
  // after 2 seconds or so, everthing will have correct value and we will have a good UX


  var _useState = useState(LOW_STOCK_QUANTITY),
      _useState2 = _slicedToArray(_useState, 2),
      variantStock = _useState2[0],
      setVariantStock = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      variantPricing = _useState4[0],
      setVariantPricing = _useState4[1];

  var availableQuantity = getAvailableQuantity(cartItems, variantId, variantStock);
  var isOutOfStock = !!variantId && variantStock < 1;
  var noPurchaseAvailable = !product.isAvailableForPurchase && !product.availableForPurchase;
  var purchaseAvailableDate = !product.isAvailableForPurchase && product.availableForPurchase && Date.parse(product.availableForPurchase);
  var isNoItemsAvailable = !(!!variantId && !isOutOfStock && availableQuantity); // const isLowStock =
  //   !!variantId &&
  //   !isOutOfStock &&
  //   !isNoItemsAvailable &&
  //   availableQuantity < LOW_STOCK_QUANTITY;

  var _useState5 = useState(1),
      _useState6 = _slicedToArray(_useState5, 1),
      quantity = _useState6[0];

  var disableButton = (cartItems === null || cartItems === void 0 ? void 0 : cartItems.length) > 0 ? !canAddToCart(cartItems, !noPurchaseAvailable, variantId, variantStock, quantity) : noPurchaseAvailable || isNoItemsAvailable;
  var cartLineForVariant = cartItems && cartItems.find(function (item) {
    return item.variant.id === variantId;
  });
  var currentQuantitty = cartLineForVariant ? cartLineForVariant.quantity : 0;

  var renderErrorMessage = function renderErrorMessage(message, testingContextId) {
    return React.createElement(S.ErrorMessage, {
      "data-test": "stockErrorMessage",
      "data-testId": testingContextId
    }, message);
  };

  var onVariantPickerChange = function onVariantPickerChange(_selectedAttributesValues, selectedVariant) {
    if (!selectedVariant) {
      setVariantId("");
      setVariantPricing(null);
      setVariantStock(0);
      return;
    }

    setVariantId(selectedVariant.id);
    setVariantPricing(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.pricing);
    setVariantStock(selectedVariant === null || selectedVariant === void 0 ? void 0 : selectedVariant.quantityAvailable);
  };

  var noopFunction = function noopFunction() {
    /** Do nothing */
  };

  return React.createElement(S.Wrapper, null, React.createElement(Link, {
    to: detailPageLink
  }, React.createElement(S.Title, {
    "data-test": "productTile"
  }, product.name), React.createElement(S.ProductPricing, null, getProductPrice(product.pricing, variantPricing))), React.createElement(S.VariantPicker, null, React.createElement(ProductVariantPicker, {
    productVariants: product.variants,
    onChange: onVariantPickerChange,
    selectSidebar: false,
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: noopFunction
  })), React.createElement("div", null, React.createElement(Link, {
    to: detailPageLink
  }, React.createElement(S.Image, {
    "data-test": "productThumbnail"
  }, React.createElement(Thumbnail, {
    source: product
  }))), React.createElement(AddToCartButton, {
    cartItems: cartItems,
    addItemToCart: addItemToCart,
    disabled: disableButton,
    variantId: variantId
  }), React.createElement(S.StockNotification, null, noPurchaseAvailable && renderErrorMessage(intl.formatMessage(commonMessages.noPurchaseAvailable), "notAvailable"), purchaseAvailableDate && renderErrorMessage(intl.formatMessage(commonMessages.purchaseAvailableOn, {
    date: new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(purchaseAvailableDate),
    time: new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric"
    }).format(purchaseAvailableDate)
  }), "timeRestrictedAvailability"), isNoItemsAvailable && renderErrorMessage(intl.formatMessage(!currentQuantitty ? commonMessages.outOfStock : commonMessages.noMoreItemsAvailable), "noItemsAvailable"))));
};