import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  mutation LoginWithGoogle($accessToken: String!) {\n    oauthTokenCreate(accessToken: $accessToken, backend: GOOGLE_OAUTH2) {\n      user {\n        id\n        email\n        firstName\n        lastName\n        isStaff\n      }\n      token\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { TypedMutation } from "@temp/core/mutations";
export var googleOAuth2LoginMutation = gql(_templateObject());
export var TypedGoogleLoginMutation = TypedMutation(googleOAuth2LoginMutation);