import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import _typeof from "@babel/runtime/helpers/typeof";
import { Field } from "formik";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";
export var AddressFormContent = function AddressFormContent(_ref) {
  var formRef = _ref.formRef,
      handleChange = _ref.handleChange,
      handleBlur = _ref.handleBlur,
      formId = _ref.formId,
      handleSubmit = _ref.handleSubmit,
      values = _ref.values,
      countriesOptions = _ref.countriesOptions,
      defaultValue = _ref.defaultValue,
      setFieldValue = _ref.setFieldValue,
      testingContext = _ref.testingContext,
      _ref$includeEmail = _ref.includeEmail,
      includeEmail = _ref$includeEmail === void 0 ? true : _ref$includeEmail,
      serverErrors = _ref.errors,
      validateForm = _ref.validateForm;
  // JNPL Defaults
  values = values || {};
  values.city = "Lucknow";
  values.countryArea = "Uttar Pradesh";
  var allowedCountry = {
    code: "IN",
    country: "India"
  };
  countriesOptions = [allowedCountry];
  values.country = allowedCountry;
  var intl = useIntl(); // validate form on mount

  useEffect(function () {
    if (validateForm) {
      validateForm();
    }
  }, [validateForm]);

  var buildFieldErrors = function buildFieldErrors(errors, fieldName) {
    var fieldErrors = [];

    if (_typeof(errors) === "object" && errors[fieldName]) {
      fieldErrors.push({
        message: errors[fieldName],
        field: fieldName
      });
    }

    var hasServerErrors = !!serverErrors && serverErrors.length;

    if (hasServerErrors) {
      serverErrors.forEach(function (error) {
        if (error.field === fieldName) {
          fieldErrors.push({
            message: error.message,
            field: fieldName
          });
        }
      });
    }

    return fieldErrors.length ? fieldErrors : undefined;
  };

  return React.createElement(S.AddressForm, {
    id: formId,
    ref: formRef,
    onSubmit: handleSubmit,
    "data-test": testingContext
  }, React.createElement(S.Wrapper, null, React.createElement(S.RowWithTwoCells, null, React.createElement(Field, {
    name: "firstName"
  }, function (_ref2) {
    var field = _ref2.field,
        errors = _ref2.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(commonMessages.firstName),
      autoComplete: "given-name",
      errors: buildFieldErrors(errors, "firstName")
    }, field));
  }), React.createElement(Field, {
    name: "lastName"
  }, function (_ref3) {
    var field = _ref3.field,
        errors = _ref3.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(commonMessages.lastName),
      autoComplete: "family-name",
      errors: buildFieldErrors(errors, "lastName")
    }, field));
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Field, {
    name: "companyName"
  }, function (_ref4) {
    var field = _ref4.field,
        errors = _ref4.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Company Name (Optional)",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.2950411968"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.2950411968")),
      autoComplete: "organization",
      errors: buildFieldErrors(errors, "companyName")
    }, field));
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Field, {
    name: "phone"
  }, function (_ref5) {
    var field = _ref5.field,
        errors = _ref5.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "10 digit mobile number",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.643490736"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.643490736")),
      autoComplete: "tel",
      errors: buildFieldErrors(errors, "phone")
    }, field));
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Field, {
    name: "streetAddress1"
  }, function (_ref6) {
    var field = _ref6.field,
        errors = _ref6.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Address line 1",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.1363074570"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1363074570")),
      autoComplete: "address-line1",
      errors: buildFieldErrors(errors, "streetAddress1")
    }, field));
  })), React.createElement(S.RowWithOneCell, null, React.createElement(Field, {
    name: "streetAddress2"
  }, function (_ref7) {
    var field = _ref7.field,
        errors = _ref7.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Address line 2",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.3121963259"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.3121963259")),
      autoComplete: "address-line2",
      errors: buildFieldErrors(errors, "streetAddress2")
    }, field));
  })), React.createElement(S.RowWithTwoCells, null, React.createElement(Field, {
    name: "postalCode"
  }, function (_ref8) {
    var field = _ref8.field,
        errors = _ref8.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Pincode",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.270878686"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.270878686")),
      autoComplete: "postal-code",
      errors: buildFieldErrors(errors, "postalCode")
    }, field));
  }), React.createElement(Field, {
    name: "city"
  }, function (_ref9) {
    var field = _ref9.field,
        errors = _ref9.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "City",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.253031977"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.253031977")),
      autoComplete: "address-level2",
      errors: buildFieldErrors(errors, "city"),
      disabled: true
    }, field));
  })), React.createElement(S.RowWithTwoCells, null, React.createElement(Field, {
    name: "country"
  }, function (_ref10) {
    var field = _ref10.field,
        errors = _ref10.form.errors;
    return React.createElement(InputSelect, _extends({}, field, {
      defaultValue: defaultValue,
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Country",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.1139500589"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1139500589")),
      name: "country",
      options: countriesOptions,
      value: values.country && countriesOptions && countriesOptions.find(function (option) {
        return option.code === values.country.code;
      }),
      onChange: function onChange(value, name) {
        return setFieldValue(name, value);
      },
      optionLabelKey: "country",
      optionValueKey: "code",
      errors: buildFieldErrors(errors, "country"),
      autoComplete: "country"
    }));
  }), React.createElement(Field, {
    name: "countryArea"
  }, function (_ref11) {
    var field = _ref11.field,
        errors = _ref11.form.errors;
    return React.createElement(TextField, _extends({
      disabled: true,
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "State",
        "id": "@next.components.organisms.AddressForm.AddressFormContent.1588172936"
      }, "id", "@next.components.organisms.AddressForm.AddressFormContent.1588172936")),
      autoComplete: "address-level1",
      errors: buildFieldErrors(errors, "countryArea")
    }, field));
  })), includeEmail && React.createElement(S.RowWithTwoCells, null, React.createElement(Field, {
    name: "email"
  }, function (_ref12) {
    var field = _ref12.field,
        errors = _ref12.form.errors;
    return React.createElement(TextField, _extends({
      label: intl.formatMessage(commonMessages.shortEmail),
      autoComplete: "email",
      errors: buildFieldErrors(errors, "email")
    }, field));
  }))));
};