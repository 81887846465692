import React from "react"; // import { FormattedMessage } from "react-intl";

import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
// enum TABS {
//   DESCRIPTION,
//   ATTRIBUTES,
// }
export var ProductDescription = function ProductDescription(_ref) {
  var _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      attributes = _ref.attributes;
  // const [activeTab, setActiveTab] = React.useState<TABS>(TABS.DESCRIPTION);
  return React.createElement(S.Wrapper, null, descriptionJson ? React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  }) : React.createElement("p", null, description));
};