import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: auto;\n  height: auto;\n  max-width: 100%;\n\n  > img {\n    width: auto;\n    height: auto;\n    max-width: 100%;\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin: 2.5rem 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(2, minmax(min-content, max-content));\n    grid-gap: 0.6rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1.5rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  grid-gap: 2rem;\n\n  ", "\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var List = styled.div(_templateObject(), media.largeScreen(_templateObject2()), media.smallScreen(_templateObject3()));
export var Loader = styled.div(_templateObject4());
export var Image = styled.div(_templateObject5());